import { listQueryParamsSchema, metaSchema } from 'api/axiosConfig';
import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { EnumIntegradoDominio, EnumSituacaoCliente } from 'constants/clientes';
import { z } from 'zod';

const schemaItemListarClientesSimplesCteResponse = z.object({
  razaoSocial: z.string(),
  nomeFantasia: z.string(),
  cpfCnpj: z.string(),
  situacao: z.number(),
  cidadeUf: z.string(),
  diasCliente: z.number(),
  codigo: z.number(),
  dataVencCertificado: z.string().datetime().nullable(),
  email: z.string(),
  sipNome: z.string().optional(),
  fone: z.string().optional(),
  tipoCliente: z.number().optional(),
  integradoDominio: z.nativeEnum(EnumIntegradoDominio),
});

const schemaExtraListarClientesSimplesCteResponse = z.object({
  ativos: z.number(),
  bloqueados: z.number(),
  inativos: z.number(),
  sisCodigo: z.number(),
  vinculados: z.number(),
});

const schemaListarClientesSimplesCteResponse = z.object({
  data: schemaItemListarClientesSimplesCteResponse.array(),
  extra: schemaExtraListarClientesSimplesCteResponse,
  meta: metaSchema,
});

const schemaListarClientesSimplesCteParams = z
  .object({
    search: z.string().optional(),
    situacao: z.nativeEnum(EnumSituacaoCliente).optional(),
    sis_codigo_contratado: z.string().optional(),
  })
  .merge(listQueryParamsSchema)
  .optional();

export const parseListarClientesSimplesCteResponse = ZodApiResponseParser.createParser(
  schemaListarClientesSimplesCteResponse,
);
export const parseListarClientesSimplesCteParams = ZodApiParamsParser.createParser(
  schemaListarClientesSimplesCteParams,
);

export type ListarClientesSimplesCteResponse = z.output<typeof schemaListarClientesSimplesCteResponse>;
export type ItemListarClientesSimplesCteResponse = z.output<typeof schemaItemListarClientesSimplesCteResponse>;
export type ListarClientesSimplesCteParams = z.infer<typeof schemaListarClientesSimplesCteParams>;
