import type { FC } from 'react';

import { base64ToBlob } from '@s_mart/utils';
import { lineCopy } from '@s_mart/light-icons';
import { linePrint } from '@s_mart/regular-icons';
import { lineBarcode } from '@s_mart/solid-icons';
import { Button, Dialog, LIcon } from '@s_mart/core';

import { Grid2, Typography, useMediaQuery } from '@mui/material';

import type { ModalBoletoProps } from '../modalPendenciaFinanceira.types';
import useAppStore from 'store/app/app';
import useAuthStore from 'store/auth/auth';
import { copyToClipboard } from 'utils/copyToClipboard';

const ModalBoleto = ({ cobranca, onClose, onJaRealizeiPagamento, onFinalizar }: ModalBoletoProps) => {
  const empresaBloqueada = useAuthStore((state) => state.bloqueado);
  const setNotification = useAppStore((state) => state.setNotification);

  const setLoading = useAppStore((state) => state.setLoading);
  const handleErrors = useAppStore((state) => state.handleErrors);

  const linhaDigitavel = cobranca?.linhaDigitavel;
  const linhaDigitavelResponsiva = useMediaQuery('(max-width:550px)') ? (
    <>
      {linhaDigitavel?.slice(0, linhaDigitavel.length / 2)} <br />{' '}
      {linhaDigitavel?.slice(linhaDigitavel.length / 2, linhaDigitavel.length)}{' '}
    </>
  ) : (
    linhaDigitavel
  );

  const copiarCodigoBarras = async () => {
    await copyToClipboard(linhaDigitavel!);

    setNotification({
      title: 'Código de barras copiado',
    });
  };

  const imprimirBoleto = async () => {
    try {
      setLoading(true);

      if (cobranca?.base64Boleto) {
        window.open(URL.createObjectURL(base64ToBlob(cobranca.base64Boleto, 'application/pdf')));
      }

      setLoading(false);
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <Dialog open disableBackdropClick disableEscapeKeyDown maxWidth='xs'>
      <Dialog.Header>
        <Grid2 container spacing={2}>
          <Grid2
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            size={12}
          >
            <LIcon icon={lineBarcode} size='20px' removeMargin style={{ marginRight: '0.5rem' }} />

            <Typography variant='titleSM'>Abaixo seu boleto atualizado</Typography>
          </Grid2>
        </Grid2>
      </Dialog.Header>
      <Dialog.Content>
        <Grid2 container>
          <Grid2 size={12}>
            <Typography variant='bodyMD'>1. Abra o aplicativo do seu banco</Typography>
          </Grid2>

          <Grid2 size={12}>
            <Typography variant='bodyMD'>2. Escolha pagar conta</Typography>
          </Grid2>

          <Grid2
            sx={{
              marginBottom: '1.5rem',
            }}
            size={12}
          >
            <Typography variant='bodyMD'>3. Copie e cole o código de barras abaixo</Typography>
          </Grid2>

          <Grid2
            style={{
              background: '#f4f5f7',
              padding: '0.5rem 1rem ',
              borderRadius: '8px',
              marginBottom: '1rem',
              display: 'flex',
              justifyContent: 'center',
            }}
            size={12}
          >
            <Typography variant='titleXS'>{linhaDigitavelResponsiva}</Typography>
          </Grid2>

          <Grid2
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}
            size={12}
          >
            <Button
              variant='outlined'
              color='neutral'
              startIcon={<LIcon icon={linePrint} size='20px' />}
              onClick={imprimirBoleto}
            >
              Imprimir boleto
            </Button>

            <Button variant='outlined' startIcon={<LIcon icon={lineCopy} size='20px' />} onClick={copiarCodigoBarras}>
              Copiar código de barras
            </Button>
          </Grid2>

          <Grid2 size={12}>
            <Typography variant='bodyMD'>O pagamento leva até 1 dia útil para compensar</Typography>
          </Grid2>
        </Grid2>
      </Dialog.Content>
      <Dialog.Footer>
        <Button variant='neutral' onClick={onClose}>
          Voltar
        </Button>
        {empresaBloqueada ? (
          <Button onClick={onJaRealizeiPagamento}>Já realizei o pagamento</Button>
        ) : (
          <Button onClick={onFinalizar}>Finalizar</Button>
        )}
      </Dialog.Footer>
    </Dialog>
  );
};

export default ModalBoleto;
