import { RouterProvider } from 'react-router';
import { useVerificarAutenticacao } from 'hooks/useVerificarAutenticacao';
import { rotasSistema } from './static.routes';
import { withAutenticationRoutes } from 'containers/hocs/withAutenticationRoutes';

const Router = () => {
  useVerificarAutenticacao();

  return <RouterProvider router={rotasSistema} />;
};

export default withAutenticationRoutes(Router);
