import { useState } from 'react';
import { useNavigate } from 'react-router';
import { type PathSourceProps, LIcon, AppBar as AppBarSmart } from '@s_mart/core';
import {
  lineSignOut,
  lineUserTie,
  lineQuestionCircle,
  lineBoxUSD,
  lineLandmark,
  lineUsers,
  lineCog,
} from '@s_mart/light-icons';
import { Divider, MenuItem, Typography } from '@mui/material';
import { opcoesAjudaSimplesCte } from './appBar.static';
import { AppBarButton, HelpButton } from './appBar.styles';
import { HelpMenu } from './helpMenu/helpMenu';
import useAuthStore from 'store/auth/auth';
import { lineChevronDown } from '@s_mart/regular-icons';
import type { HelpMenuState } from './appBar.types';
import { BotaoPendenciaFinanceira } from './botaoPendenciaFinanceira/botaoPendenciaFinanceira';

export const AppBarSimplesCTe = ({ setModalMultiempresa }: { setModalMultiempresa: (state: boolean) => void }) => {
  const [perfil, logout] = useAuthStore((state) => [state.perfil, state.logout]);
  const [menuOpen, setMenuOpen] = useState<HelpMenuState | null>(null);
  const navigate = useNavigate();

  return (
    <AppBarSmart>
      <AppBarSmart.LeftSide>
        <div style={{ marginInline: '1.5rem' }}>
          <BotaoPendenciaFinanceira />
        </div>
      </AppBarSmart.LeftSide>
      <AppBarButton menuOpen={menuOpen} onClick={(e) => setMenuOpen(e.currentTarget)}>
        <LIcon icon={lineQuestionCircle} size='28px' removeMargin />
        <Typography variant='bodySM'>Ajuda</Typography>
        <LIcon icon={lineChevronDown} size='18px' removeMargin />
      </AppBarButton>
      <AppBarSmart.Profile title={perfil.razao_social} user={perfil.nome}>
        <AppBarSmart.Action
          icon={<LIcon icon={lineLandmark} />}
          onClick={() =>
            navigate({
              pathname: '/config/minha-conta',
              search: `?tab=minhaEmpresa`,
            })
          }
        >
          Minha conta
        </AppBarSmart.Action>
        <AppBarSmart.Action
          icon={<LIcon icon={lineBoxUSD} />}
          onClick={() =>
            navigate({
              pathname: '/config/minha-conta',
              search: `?tab=meuPlano`,
            })
          }
        >
          Meu plano
        </AppBarSmart.Action>
        <Divider orientation='horizontal' />
        <AppBarSmart.Action icon={<LIcon icon={lineUsers} />} onClick={() => navigate('/config/usuarios')}>
          Usuários do escritório
        </AppBarSmart.Action>
        <AppBarSmart.Action icon={<LIcon icon={lineCog} />} onClick={() => navigate('/config')}>
          Configurações
        </AppBarSmart.Action>
        {perfil.multi && (
          <AppBarSmart.Action icon={lineUserTie} onClick={() => setModalMultiempresa(true)}>
            Trocar empresa
          </AppBarSmart.Action>
        )}
        <Divider orientation='horizontal' />
        <AppBarSmart.Action icon={lineSignOut} onClick={logout}>
          Sair
        </AppBarSmart.Action>
      </AppBarSmart.Profile>
      <HelpMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
        {opcoesAjudaSimplesCte(perfil).map((item) => (
          <MenuItem onClick={item.onClick} key={item.label}>
            <LIcon icon={item.icon as PathSourceProps} size='18px' removeMargin />
            <Typography variant='bodySM' sx={{ ml: '0.5rem' }}>
              {item.label}
            </Typography>
          </MenuItem>
        ))}
      </HelpMenu>
    </AppBarSmart>
  );
};
