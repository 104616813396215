import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import type { FC } from 'react';
import { Navigate } from 'react-router';
import useAuthStore from 'store/auth/auth';
import type { AuthState } from 'store/auth/auth.types';

export const publicRouteMiddleware = (Component: FC) => {
  let tokenValido = false;

  return function PublicComponent() {
    const _token = Cookies.get('id_token');
    const _rotaInicial = useAuthStore((state) => state.rotaPrivadaInicial);

    try {
      tokenValido = !!jwtDecode<AuthState['perfil']>(_token!);
    } catch (_) {
      tokenValido = false;
    }

    if (tokenValido) {
      return <Navigate to={_rotaInicial} />;
    }

    return <Component />;
  };
};
