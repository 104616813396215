import { BottomBar as SBottomBar } from '@s_mart/core';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import useAuthStore from 'store/auth/auth';
import { rotasDrawerAgro } from 'components/layout/private/drawer/drawer.static';
import { EnumAutomacaoAgronota } from 'constants/configuracoes';

const BottomBar = () => {
  const { pathname } = useLocation();
  const _navigate = useNavigate();
  const recursosDisponiveis = useAuthStore((state) => state.recursosDisponiveis);
  const migrouAutomacaoSAAS = useAuthStore(
    (s) => s.configuracoes?.automacaoAgronota === EnumAutomacaoAgronota.CentralFinanceira,
  );
  if (recursosDisponiveis === null) {
    return <></>;
  }
  return (
    <Box
      sx={{
        '& > nav': {
          height: '60px',
        },
      }}
    >
      <SBottomBar>
        {rotasDrawerAgro(recursosDisponiveis, migrouAutomacaoSAAS).map((item) => {
          return (
            <SBottomBar.Item
              key={item.path}
              icon={item.icon}
              onPress={() => _navigate(`/${item.path}`)}
              path={item.path}
              selected={pathname.includes(item.path)}
              hide={item.hide}
            >
              {item.label}
            </SBottomBar.Item>
          );
        })}
        <SBottomBar.Menu headerText='Menu' />
      </SBottomBar>
    </Box>
  );
};

export default BottomBar;
