import { Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { Button } from '@s_mart/core';
import { valor } from '@s_mart/masks';
import { colorPalette } from '@s_mart/tokens';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import type { ModalPendenciaFinanceiraMobileContentProps } from './modalPendenciaFinanceira.types';

export const ModalPendenciaFinanceiraMobileContent = ({
  cobrancasEmAberto,
  onPagarViaBoleto,
  onPagarViaPix,
  empresaBloqueada,
}: ModalPendenciaFinanceiraMobileContentProps) => {
  return (
    <Grid2
      container

      display='flex'
      justifyContent='center'
      alignItems='center'
      border={`1px solid ${colorPalette.neutral[30]}`}
      borderRadius='8px'
      padding='1rem'
    >
      {cobrancasEmAberto.map((boleto, index) => {
        return (
          <Fragment key={boleto.codigo}>
            {index > 0 && (
              <Grid2 size={11}>
                <Divider style={{ padding: '0.2rem 0' }} />
              </Grid2>
            )}
            <Grid2 paddingY='0.2rem' size={12}>
              <Typography variant='bodySM' color={colorPalette.red[500]}>
                Vencimento: {dayjs(boleto.dataVencimento).format('DD/MM/YYYY')}
              </Typography>
            </Grid2>
            <Grid2 paddingY='0.2rem' size={12}>
              <Typography variant='titleXS'>Valor: {valor.format(boleto.valor)}</Typography>
            </Grid2>
            {boleto.base64Boleto && (
              <Grid2 paddingY='0.2rem' size={12}>
                <Button onClick={() => onPagarViaBoleto(boleto)} variant='neutral' fullWidth>
                  Pagar via boleto
                </Button>
              </Grid2>
            )}
            {boleto.pixQrCode && (
              <Grid2 paddingY='0.2rem' size={12}>
                <Button onClick={() => onPagarViaPix(boleto)} fullWidth>
                  Pagar via Pix {empresaBloqueada && ' e desbloquear agora'}
                </Button>
              </Grid2>
            )}
          </Fragment>
        );
      })}
    </Grid2>
  );
};
