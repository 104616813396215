export enum EnumIntegradoDominio {
  NAO = 0,
  SIM = 1,
}

export enum EnumSituacaoCliente {
  INATIVO,
  ATIVO,
  BLOQUEADO,
}
