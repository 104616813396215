import type { FC } from 'react';

import { lineWhatsApp } from '@s_mart/regular-icons';
import { Button, Dialog, LIcon } from '@s_mart/core';

import { Grid2, Typography } from '@mui/material';

import type { ModalPagamentoNaoIdentificadoProps } from '../modalPendenciaFinanceira.types';
import { Config } from 'config';

const ModalPagamentoNaoIdentificado = ({ onClose }: ModalPagamentoNaoIdentificadoProps) => {
  return (
    <Dialog open disableBackdropClick disableEscapeKeyDown maxWidth='sm'>
      <Dialog.Header>Pagamento não identificado</Dialog.Header>
      <Dialog.Content>
        <Grid2 container spacing={3}>
          <Grid2 size={12}>
            <Typography variant='bodyMD'>Verifique no extrato do seu banco se o pagamento foi realizado</Typography>
          </Grid2>

          <Grid2 size={12}>
            <Typography variant='bodyMD'>
              <strong>● Pagamento realizado com sucesso:</strong> solicite o desbloqueio para o nossa equipe.
            </Typography>
          </Grid2>

          <Grid2 size={12}>
            <Typography variant='bodyMD'>
              <strong>● Pagamento não foi realizado: </strong> você pode tentar novamente.
            </Typography>
          </Grid2>
        </Grid2>
      </Dialog.Content>
      <Dialog.Footer>
        <Button variant='neutral' onClick={onClose}>
          Tentar pagar novamente
        </Button>

        <Button
          startIcon={<LIcon icon={lineWhatsApp} removeMargin />}
          onClick={() => {
            window.open(`${Config.suportURL}&text=Olá, quero desbloquear o sistema`, '_blank');
          }}
        >
          Solicitar desbloqueio
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default ModalPagamentoNaoIdentificado;
