import { useCallback, useEffect } from 'react';
import { useNotification as useNotificationSmart } from '@s_mart/notistack';
import useAppStore from 'store/app/app';

export const useNotification = () => {
  const [notification, setNotification] = useAppStore((s) => [s.notification, s.setNotification]);
  const notificationSmart = useNotificationSmart();

  const _handleClose = useCallback(() => {
    setNotification(null);
  }, [setNotification]);

  useEffect(() => {
    if (notification && typeof notification !== 'function') {
      notificationSmart.setNotification({
        ...notification,
        title: notification.title ?? '',
      });

      _handleClose();
    }
  }, [notification, _handleClose, notificationSmart]);
};
