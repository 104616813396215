import { Menu } from '@mui/material';
import { type PropsWithChildren, memo } from 'react';

interface IMenu extends PropsWithChildren {
  menuOpen: (EventTarget & HTMLDivElement) | null;
  setMenuOpen: (menuOpen: (EventTarget & HTMLDivElement) | null) => void;
}

export const HelpMenu = memo(({ menuOpen, setMenuOpen, children }: IMenu) => {
  return (
    <Menu
      open={Boolean(menuOpen)}
      onClose={() => setMenuOpen(null)}
      anchorEl={menuOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {children}
    </Menu>
  );
});

HelpMenu.displayName = 'HelpMenu';
