import { Dialog, Button, LIcon } from '@s_mart/core';
import { Typography, Grid } from '@mui/material';
import { lineWhatsApp } from '@s_mart/regular-icons';

import { useIntegrarComDominioDialogStore } from '../integrarComDominioDialog/integrarComDominioDialog.store';
import { Config } from 'config';

const imgPasso2 = 'static/images/dominio/tutorial-passo2.png';
const imgPasso3 = 'static/images/dominio/tutorial-passo3.png';
const imgPasso4 = 'static/images/dominio/tutorial-passo4.png';
const imgPasso6 = 'static/images/dominio/tutorial-passo6.png';

export function TutorialCadastroChaveDominioDialog() {
  const { openTutorialCadastroChave, handleCloseTutorialCadastroChave } = useIntegrarComDominioDialogStore();

  const handleOpenWhatsApp = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${Config.systemData.whatsApp}&text=Olá, preciso de ajuda para integrar com a Domínio.`,
      '_blank',
    );
  };

  return (
    <Dialog
      open={openTutorialCadastroChave}
      onClose={handleCloseTutorialCadastroChave}
      closeIcon
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Dialog.Header>
        <Typography variant='titleMD'>Integrar com a Domínio</Typography>
      </Dialog.Header>
      <Dialog.Content>
        <Grid
          container
          sx={{
            gap: 3,
          }}
        >
          <Grid item xs={12}>
            <Typography variant='titleXS'>Esse processo deverá ser feito para cada cliente.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='bodyMD'>
              1 - Para cadastrar uma nova chave, acesse o <b>Onvio Gestão</b> e clique no menu <b>API</b>;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                gap: 1,
              }}
            >
              <Grid item xs={12}>
                <Typography variant='bodyMD'>
                  2 - Na aba Chaves clique em <b>Adicionar;</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={imgPasso2} alt='Clicar no botão adicionar na aba chave' width={150} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                gap: 1,
              }}
            >
              <Grid item xs={12}>
                <Typography variant='bodyMD'>
                  3 - No campo <b>descrição</b>, sugerimos colocar o <b>nome do cliente</b> que irá integrar para
                  diferenciar a chave das demais;
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={imgPasso3} alt='Preencher o campo descrição com o nome do cliente' width={250} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                gap: 1,
              }}
            >
              <Grid item xs={12}>
                <Typography variant='bodyMD'>
                  4 - Selecione a <b>empresa do seu cliente</b> e clique em <b>Habilitar;</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={imgPasso4} alt='Selecionar a empresa do cliente e clicar em habilitar' width={250} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='bodyMD'>
              5 - <b>Copie a Chave gerada pelo Onvio</b>;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                gap: 1,
              }}
            >
              <Grid item xs={12}>
                <Typography variant='bodyMD'>
                  6 - Agora <b>insira a chave de integração Domínio</b> e clique em <b>Testar chave.</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img src={imgPasso6} alt='Inserir a chave de integração Domínio' width={200} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog.Content>
      <Dialog.Footer>
        <Grid
          container
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Grid item>
            <Button startIcon={<LIcon icon={lineWhatsApp} />} variant='neutral' onClick={handleOpenWhatsApp}>
              Preciso de ajuda
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleCloseTutorialCadastroChave}>Entendi</Button>
          </Grid>
        </Grid>
      </Dialog.Footer>
    </Dialog>
  );
}
