import { base64ToBlob } from '@s_mart/utils';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import useAuthStore from 'store/auth/auth';
import useCobrancasStore from 'store/cobrancas/useCobrancasStore';
import { mapButtonPendenciaProps } from './botaoPendenciaFinanceira.static';
import { EnumSistemas } from 'constants/sistemas';
import { useLocation } from 'react-router';

let jaExibiuModalCobranca = false;
export const useBotaoPendenciaFinanceira = () => {
  const [bloqueado, realizouOnboarding] = useAuthStore((s) => [!!s.bloqueado, s.configuracoes?.onboarding === 1]);
  const sistemaPossuiOnboarding = useAuthStore(({ perfil }) =>
    [EnumSistemas.AGRONOTA].includes(perfil.sistemas[0].SistemaCodigo),
  );

  const [cobrancasEmAberto, setModalPendenciasFinanceira] = useCobrancasStore((s) => [
    s.cobrancasEmAberto,
    s.setModalPendenciasFinanceiras,
  ]);

  const { pathname } = useLocation();
  const urlMinhaConta = pathname.includes('minha-conta');

  const possuiBoletosNaoVisualizados = !!useAuthStore((s) =>
    cobrancasEmAberto?.some((c) => !c.dataPagamento && !s.configuracoesUsuario?.boletosVisualizados?.[c.identificador]),
  );

  useEffect(() => {
    const possuiOnboardingENaoFoiRealizado = sistemaPossuiOnboarding && !realizouOnboarding;

    // Não deve ser exibido a modal, caso esteja na rota '/minha-conta'
    // Não deve ser exibido caso o sistema parceiro (Ex.: SimplesCTe, Agronota...) tenha onboarding, e esse onboarding não foi realizado
    // Não exibe se não tem cobrancas
    // (XUXO) Se ja foi exibida a modal de cobranca
    if (!cobrancasEmAberto?.length || possuiOnboardingENaoFoiRealizado || urlMinhaConta || jaExibiuModalCobranca) {
      return;
    }

    // Se estiver bloqueado e tiver fatura vencida, exibe a modal
    /// OBs: Como o bloqueio pode ser feito manualmente por usuários SBSistemas, precisamos validar se existe boleto vencido
    if (bloqueado) {
      setModalPendenciasFinanceira(
        cobrancasEmAberto.some(({ dataVencimento }) => dayjs(dataVencimento).isBefore(dayjs())),
      );
    } else {
      setModalPendenciasFinanceira(
        // Valida para mostrar quando é um novo boleto
        // Se boleto não tiver sido pago e nem sido visualizado mostramos a modal
        possuiBoletosNaoVisualizados,
      );
    }

    jaExibiuModalCobranca = true;
  }, [
    bloqueado,
    urlMinhaConta,
    realizouOnboarding,
    possuiBoletosNaoVisualizados,
    sistemaPossuiOnboarding,
    cobrancasEmAberto?.length,
    cobrancasEmAberto?.some,
    setModalPendenciasFinanceira,
  ]);

  const dataVencimento = cobrancasEmAberto?.at(0)?.dataVencimento;
  const base64boleto = cobrancasEmAberto?.at(0)?.base64Boleto;

  const handleButtonClick = useCallback(() => {
    if (bloqueado) {
      setModalPendenciasFinanceira(true);
    } else if (base64boleto) {
      window.open(URL.createObjectURL(base64ToBlob(base64boleto, 'application/pdf')));
    }
  }, [setModalPendenciasFinanceira, bloqueado, base64boleto]);

  const botaoPendenciaFinanceiraProps = useMemo(() => {
    if (!base64boleto || !dataVencimento) {
      return null;
    }

    if (bloqueado) {
      // biome-ignore lint/style/noNonNullAssertion: Não deve ser undefined, pois deve estar mapeado as variações
      return mapButtonPendenciaProps.get('sistemaBloqueado')!;
    }

    const diasParaOVencimentoDaCobranca = dayjs(dataVencimento, 'YYYY-MM-DD').diff(
      dayjs().format('YYYY-MM-DD'),
      'days',
    );
    if (diasParaOVencimentoDaCobranca > 3) {
      // biome-ignore lint/style/noNonNullAssertion: Não deve ser undefined, pois deve estar mapeado as variações
      return mapButtonPendenciaProps.get('cobrancaDisponivel')!;
    }

    if (diasParaOVencimentoDaCobranca >= 1) {
      // biome-ignore lint/style/noNonNullAssertion: Não deve ser undefined, pois deve estar mapeado as variações
      return mapButtonPendenciaProps.get('cobrancaProximaDoVencimento')!;
    }

    if (diasParaOVencimentoDaCobranca === 0) {
      // biome-ignore lint/style/noNonNullAssertion: Não deve ser undefined, pois deve estar mapeado as variações
      return mapButtonPendenciaProps.get('cobrancaVenceHoje')!;
    }

    // biome-ignore lint/style/noNonNullAssertion: Não deve ser undefined, pois deve estar mapeado as variações
    return mapButtonPendenciaProps.get('cobrancaVencida')!;
  }, [base64boleto, bloqueado, dataVencimento]);

  return { handleButtonClick, botaoPendenciaFinanceiraProps };
};
