import { QueryClientProvider } from '@tanstack/react-query';

import { ThemeProvider } from '@l_ine/core';
import { SmartProvider } from '@s_mart/core';

import { GlobalStyles } from 'styles/globalStyle';
import { lineTheme, smartTheme } from 'styles/themes/theme';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAnalytics } from 'hooks/useAnalytics';
import { useHead } from 'hooks/useHead';
import { useQueryClient } from 'hooks/useQueryClient';
import useSentry from 'hooks/useSentry';
import { enableMapSet } from 'immer';
import Router from 'routes/routes';
import { sistemas } from 'constants/sistemas';
import { PostHogProvider } from 'posthog-js/react';
import { usePostHogInit } from 'lib/posthog/usePostHogInit';
import { NotistackProvider } from '@s_mart/notistack';

enableMapSet();
const App = () => {
  const { initialize: initAnalytics } = useAnalytics();

  useHead();
  useSentry();
  initAnalytics();
  const posthog = usePostHogInit();

  const _queryClient = useQueryClient();

  const dominio = window.location.hostname.split('.')[1];

  return (
    <>
      <title>Parceiro - {sistemas[dominio].name}</title>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={_queryClient}>
          <ReactQueryDevtools position='bottom' buttonPosition='bottom-left' />
          <ThemeProvider theme={lineTheme}>
            <SmartProvider theme={smartTheme}>
              <NotistackProvider>
                <GlobalStyles />
                <Router />
              </NotistackProvider>
            </SmartProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </PostHogProvider>
    </>
  );
};

export default App;
