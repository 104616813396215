import type { FC } from 'react';
import useAuthStore from 'store/auth/auth';

const rotasPublicasProtegidas = ['/finalizar-contratacao', '/recuperar-senha', '/redefinir-senha', '/cadastro'];

export const withAutenticationRoutes = (Component: FC) => {
  const pathname = window.location.pathname;

  /**
   * Se o usuário estiver logado e acessar algum link de cadastro deve ser feito o logout para poder prosseguir
   */
  if (rotasPublicasProtegidas.includes(pathname)) {
    useAuthStore.getState().limparTodosStores();
  }

  return Component;
};
