import { listQueryParamsSchema, metaSchema } from 'api/axiosConfig';
import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';
import { EnumIntegradoDominio, EnumSituacaoCliente } from 'constants/clientes';

const schemaProdutor = z.object({
  codigoPessoa: z.number(),
  cpfCnpjpessoa: z.string(),
  nomePessoa: z.string(),
  possuiCertificado: z.boolean(),
});

const schemaItemListarClientesAgronotaResponse = z.object({
  razaoSocial: z.string(),
  nomeFantasia: z.string(),
  cpfCnpj: z.string(),
  situacao: z.number(),
  cidadeUf: z.string(),
  diasCliente: z.number(),
  codigo: z.number(),
  dataVencCertificado: z.string().datetime().nullable(),
  email: z.string(),
  sipNome: z.string().optional(),
  fone: z.string().optional(),
  tipoCliente: z.number().optional(),
  integradoDominio: z.nativeEnum(EnumIntegradoDominio),
  clientes: z.array(schemaProdutor),
});

const schemaExtraListarClientesAgronotaResponse = z.object({
  ativos: z.number(),
  bloqueados: z.number(),
  inativos: z.number(),
  sisCodigo: z.number(),
  vinculados: z.number(),
});

const schemaListarClientesAgronotaResponse = z.object({
  data: schemaItemListarClientesAgronotaResponse.array(),
  extra: schemaExtraListarClientesAgronotaResponse,
  meta: metaSchema,
});

const schemaListarClientesAgronotaParams = z
  .object({
    search: z.string().optional(),
    situacao: z.nativeEnum(EnumSituacaoCliente).optional(),
    sis_codigo_contratado: z.string().optional(),
  })
  .merge(listQueryParamsSchema)
  .optional();

export const parseListarClientesAgronotaResponse = ZodApiResponseParser.createParser(
  schemaListarClientesAgronotaResponse,
);
export const parseListarClientesAgronotaParams = ZodApiParamsParser.createParser(schemaListarClientesAgronotaParams);

export type ListarClientesAgronotaResponse = z.output<typeof schemaListarClientesAgronotaResponse>;
export type ItemListarClientesAgronotaResponse = z.output<typeof schemaItemListarClientesAgronotaResponse>;
export type ListarClientesAgronotaParams = z.infer<typeof schemaListarClientesAgronotaParams>;
