import { ZodApiBodyParser } from 'api/zodApi';
import { EnumTipoParticipante } from 'constants/naturezasOperacao';

import { z } from 'zod';

export const schemaInserirRegraNaturezaMetricasBody = z.object({
  tempoUFeProduto: z.number().nullish(),
  tempoTributacoes: z.number().nullish(),
});

const schemaTributacoesRegraNaturezaInserirBody = z.object({
  aliquotaDiferimento: z.number().optional().nullable(),
  cofinsAliquota: z.number().optional().nullable(),
  cofinsAliquotaReais: z.number().optional().nullable(),
  cofinsCst: z.number(),
  cofinsPercentualBc: z.number().optional().nullable(),
  icmsAliquota: z.number().optional().nullable(),
  icmsCst: z.number().optional().nullable(),
  icmsDesoneradoMotivo: z.number().optional().nullable(),
  icmsPercBc: z.number().optional().nullable(),
  icmsStAliquota: z.number().optional().nullable(),
  icmsStModalidadeBc: z.number().optional().nullable(),
  infoAdFisco: z.string().optional().nullable(),
  modBC: z.number(),
  origemMercadoria: z.number(),
  pisAliquota: z.number().optional().nullable(),
  pisAliquotaReais: z.number().optional().nullable(),
  pisCst: z.number(),
  pisPercentualBc: z.number().optional().nullable(),
  tipoParticipante: z.nativeEnum(EnumTipoParticipante),
  valorUnitarioPauta: z.number().optional().nullable(),
  cBenef: z.string().optional().nullable(),
});

const schemaRegraNaturezaInserirBody = z.object({
  cfop: z.number(),
  destino: z.array(
    z.object({
      codigo: z.number(),
      uf: z.string(),
    }),
  ),
  metricas: schemaInserirRegraNaturezaMetricasBody.optional(),
  ncm: z.array(
    z.object({
      nome: z.string(),
      ncm: z.string(),
    }),
  ),
  origem: z.array(
    z.object({
      codigo: z.number(),
      uf: z.string(),
    }),
  ),
  produtos: z.array(z.number()),
  tributacoes: z.array(schemaTributacoesRegraNaturezaInserirBody),
});

const schemaEstadoRegraEditar = z.object({
  codigoEstado: z.number(),
  codigoRegraEstado: z.number().optional(),
  uf: z.string(),
});

const schemaTributacaoRegraNaturezaEditarBody = z
  .object({
    codigoTributacao: z.number().optional(),
  })
  .merge(schemaTributacoesRegraNaturezaInserirBody);

const schemaRegraNaturezaEditarBody = z.object({
  codigoRegra: z.number().optional(),
  cfop: z.number(),
  destino: z.array(schemaEstadoRegraEditar),
  ncm: z.array(
    z.object({
      codigoRegraNcm: z.number().optional(),
      ncm: z.string(),
      nome: z.string(),
    }),
  ),
  origem: z.array(schemaEstadoRegraEditar),
  produtos: z.array(
    z.object({
      codigoProduto: z.number(),
      codigoRegraProduto: z.number().optional(),
    }),
  ),
  tributacoes: z.array(schemaTributacaoRegraNaturezaEditarBody),
});

const schemaInserirNaturezaMetricasBody = z.object({
  tempoCadastro: z.number().nullish(),
  tempoInativo: z.number().nullish(),
});

const schemaInserirNaturezaOperacaoBody = z.object({
  finalidade: z.number(),
  naturezaNome: z.string(),
  operacao: z.number(),
  regra: z.array(schemaRegraNaturezaInserirBody),
  tipo: z.number(),
  metricas: schemaInserirNaturezaMetricasBody.optional(),
});

const schemaEditarNaturezaOperacaoBody = z.object({
  codigoNatureza: z.number(),
  finalidade: z.number(),
  naturezaNome: z.string(),
  operacao: z.number(),
  regra: z.array(schemaRegraNaturezaEditarBody),
  tipo: z.number(),
});

export const parseInserirNaturezaOperacaoBody = ZodApiBodyParser.createParser(schemaInserirNaturezaOperacaoBody);
export const parseEditarNaturezaOperacaoBody = ZodApiBodyParser.createParser(schemaEditarNaturezaOperacaoBody);

export type InserirRegraNaturezaMetricas = z.infer<typeof schemaInserirRegraNaturezaMetricasBody>;
export type InserirNaturezaMetricas = z.infer<typeof schemaInserirNaturezaMetricasBody>;
export type InserirNaturezaOperacaoBody = z.infer<typeof schemaInserirNaturezaOperacaoBody>;
export type InserirTributacaoRegraNaturezaBody = z.infer<typeof schemaTributacoesRegraNaturezaInserirBody>;
export type RegraNaturezaInserir = z.infer<typeof schemaRegraNaturezaInserirBody>;
export type EditarNaturezaOperacaoBody = z.infer<typeof schemaEditarNaturezaOperacaoBody>;
export type EstadoRegraEditarBody = z.infer<typeof schemaEstadoRegraEditar>;
export type RegraNaturezaEditarBody = z.infer<typeof schemaRegraNaturezaEditarBody>;
export type EditarTributacaoRegraNaturezaBody = z.infer<typeof schemaTributacaoRegraNaturezaEditarBody>;
