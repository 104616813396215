import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@emotion/react';

import { useIntegrarComDominioDialogStore } from '../integrarComDominioDialog.store';
import type { IntegrarComDominioFormValues } from '../integrarComDominioDialog.types';

import { Button, Indicator, LIcon } from '@s_mart/core';
import { TextField } from '@s_mart/form';
import { lineWhatsApp } from '@s_mart/regular-icons';
import { lineExclamationTriangle } from '@s_mart/solid-icons';
import { required } from '@s_mart/rules';

import { useConteudoPrimeiroPasso } from './useConteudoPrimeiroPasso';

export function ConteudoPrimeiroPasso() {
  const { watch } = useFormContext<IntegrarComDominioFormValues>();
  const { textoSistema, handleOpenWhatsApp } = useConteudoPrimeiroPasso();
  const { handleOpenTutorialCadastroChave } = useIntegrarComDominioDialogStore();

  const { palette } = useTheme();

  const _erroChaveDominio = watch('erroChaveDominio');

  return (
    <Grid
      container
      sx={{
        gap: 2,
        justifyContent: 'center',
      }}
    >
      <Grid item xs={12}>
        <Typography variant='bodyMD'>
          A partir da data da integração, {textoSistema} para o sistema da Domínio de forma automática.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='bodyMD'>
          Para integrar, crie uma <strong>chave do sistema Domínio.</strong>{' '}
          {
            <a href='#' onClick={handleOpenTutorialCadastroChave} style={{ color: palette.primary.main }}>
              Veja como cadastrar uma chave de integração.
            </a>
          }
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid
          container
          sx={{
            gap: 2,
          }}
        >
          <Grid item xs={12}>
            <TextField
              label='Insira a chave de integração'
              name='chaveDominio'
              helperText='Exemplo de chave: 9562e184n398498nhfsadf808333'
              size='large'
              rules={required}
            />
          </Grid>
          <Grid item xs={12}>
            {_erroChaveDominio && (
              <Indicator color='error' icon={<LIcon icon={lineExclamationTriangle} />}>
                {_erroChaveDominio}
              </Indicator>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type='submit' variant='contained' color='primary' size='large' fullWidth>
              Testar chave
            </Button>
          </Grid>
          {_erroChaveDominio && (
            <Grid item xs={12}>
              <Button
                startIcon={<LIcon icon={lineWhatsApp} />}
                variant='outlined'
                size='large'
                fullWidth
                onClick={handleOpenWhatsApp}
              >
                Preciso de ajuda
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
