import { type PropsWithChildren, useCallback } from 'react';
import { Outlet } from 'react-router';
import 'moment/locale/pt-br';
import { ContentContainer, OutletContainer, PrivateContainer } from './private.styles';
import { Loading } from '../..';
import { AppBar } from './appBar/appBar';
import { Drawer } from './drawer/drawer';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import BottomBar from 'components/bottomBar/bottomBar';
import OnboardingAgronota from 'containers/onboarding/agronota/onboardingAgronota';
import { ConfirmationServiceProvider } from 'contexts/confirmationContext';
import { useNotification } from 'hooks/useNotification/useNotification';
import { BannerContratacao } from 'shared/bannerContratacao/bannerContratacao';
import { Dialogs } from './dialogs/dialogs';
import { usePrivate } from './usePrivate';
import { EscrituracaoRetroativaV2Provider } from 'contexts/escrituracaoRetroativaV2/escrituracaoRetroativaV2';
import { EnumSistemas } from 'constants/sistemas';
import { privateRouteMiddleware } from 'containers/hocs/privateRouteMiddleware/privateRouteMiddleware';
import { usePostHogTrackPages } from 'lib/posthog/usePostHogTrackPages';
import ModalPendenciaFinanceira from 'shared/modalPendenciaFinanceira/modalPendenciaFinanceira';
import { Config } from 'config';
import { DialogsProvider } from '@toolpad/core/useDialogs';

const Layout = () => {
  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down(768));
  /**
   * Hook para exibir notificações
   */

  useNotification();
  /**
   * Hooks com diversas funções de uso geral
   */
  const { mostrarOnboarding, token, meusDados } = usePrivate();

  usePostHogTrackPages();

  const Providers = useCallback(({ children }: PropsWithChildren) => {
    if (Config.systemData.code === EnumSistemas.AGRONOTA) {
      return <EscrituracaoRetroativaV2Provider>{children}</EscrituracaoRetroativaV2Provider>;
    }

    return <>{children}</>;
  }, []);

  return (
    <DialogsProvider>
      <ConfirmationServiceProvider>
        <PrivateContainer>
          <Loading />
          {isMobile ? <BottomBar /> : <Drawer />}
          <ContentContainer>
            <AppBar />
            <BannerContratacao />

            <ModalPendenciaFinanceira />

            {mostrarOnboarding && <OnboardingAgronota />}
            <Dialogs />
            <OutletContainer dataContratacao={meusDados?.dataContratacao}>
              <Providers>
                <Outlet />
              </Providers>
            </OutletContainer>
          </ContentContainer>
        </PrivateContainer>
      </ConfirmationServiceProvider>
    </DialogsProvider>
  );
};

export default privateRouteMiddleware(Layout);
