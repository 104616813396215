import { Button, Dialog } from '@s_mart/core';
import { useEscrituracaoRetroativaV2Context } from '../escrituracaoRetroativaV2';
import { ANALYTICS_ACTIONS } from 'constants/analyticsActions';
import useAnalytics from 'hooks/useAnalytics';
import { Box, Stack, Typography } from '@mui/material';

type ModalExplicacaoDaEscrituracaoRetroativaProps = {
  onClose: () => void;
};

export const ModalExplicacaoDaEscrituracaoRetroativa = ({ onClose }: ModalExplicacaoDaEscrituracaoRetroativaProps) => {
  const { analyticsEventTracker } = useAnalytics();

  const { escriturarRetroativamenteMutation } = useEscrituracaoRetroativaV2Context();

  const handleRevisarNotasPendentes = () => {
    onClose();
    analyticsEventTracker({ action: ANALYTICS_ACTIONS.ESCRITURACAO_AUTOMATICA.ESCRITURACAO_RETROATIVA });
    escriturarRetroativamenteMutation();
  };

  return (
    <Dialog open onClose={onClose} closeIcon>
      <Dialog.Header>Lançar notas pendentes</Dialog.Header>
      <Dialog.Content>
        <Stack
          sx={{
            gap: 1,
          }}
        >
          <Typography paragraph variant='bodySM'>
            Ao confirmar vamos tentar lançar novamente todas as notas que estão pendentes.
          </Typography>
          <Typography variant='bodySM'>
            Fique tranquilo, o que já foi lançado ou ignorado não vai ser alterado.
          </Typography>
        </Stack>
      </Dialog.Content>
      <Dialog.Footer>
        <Button onClick={onClose} variant='neutral'>
          Cancelar
        </Button>
        <Button onClick={handleRevisarNotasPendentes}>Confirmar</Button>
      </Dialog.Footer>
    </Dialog>
  );
};
