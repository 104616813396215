import { createSearchParams, type NavigateFunction } from 'react-router';
import type { Perfil } from 'store/auth/auth.types';
import { lineBoxUSD, lineCog, lineCommentAlt, lineLandmark, lineUsers } from '@s_mart/light-icons';
import { lineWhatsApp } from '@s_mart/regular-icons';
import { lineInfoCircle } from '@s_mart/solid-icons';
import { freshChat } from 'utils/freshchat';
import { primaryColor } from 'styles/themes/theme';
import { Config } from 'config';

export const opcoesAjudaAgro = [
  {
    label: 'Central de ajuda',
    icon: lineInfoCircle,
    color: primaryColor,
    onClick: () => {
      window.open('https://ajuda.agronota.com.br/', '_blank');
    },
  },
  {
    label: 'WhatsApp',
    icon: lineWhatsApp,
    onClick: () => {
      window.open(`https://api.whatsapp.com/send?phone=${Config.systemData.whatsApp}`, '_blank');
    },
  },
  {
    label: 'AnyDesk',
    imgPath: '/static/svgs/anyDeskIcon.svg',
    onClick: () => {
      window.open('https://anydesk.com/pt/downloads', '_blank');
    },
  },
];

export const opcoesAjudaSimplesCte = (profile: Perfil) => [
  {
    label: 'WhatsApp',
    icon: lineWhatsApp,
    onClick: () => {
      window.open(`https://api.whatsapp.com/send?phone=${Config.systemData.whatsApp}`, '_blank');
    },
  },
  {
    label: 'Chat',
    icon: lineCommentAlt,
    onClick: () => {
      if (!Config.systemData.freshchat) {
        window.open(`https://api.whatsapp.com/send?phone=${Config.systemData.whatsApp}`, '_blank');
      } else {
        freshChat(profile);
      }
    },
  },
];
