import { Box, Stack, Typography } from '@mui/material';
import { Button } from '@s_mart/core';
import { ImagePeview } from 'components/imagePreview/imagePeview';
import type { NovidadeItem } from '../novidades.types';
import useAnalytics from 'hooks/useAnalytics';
import { ANALYTICS_ACTIONS } from 'constants/analyticsActions';

export const NovidadesCard = ({
  descricao,
  titulo: title,
  data,
  img,
  labelRedirect = 'Quero saber mais',
  url,
}: NovidadeItem) => {
  const { analyticsEventTracker } = useAnalytics();

  return (
    <Box
      sx={{
        bgcolor: 'white',
        p: 2,
        borderRadius: 4,
      }}
    >
      <Stack
        sx={{
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='titleSM'>{title}</Typography>
          <Typography variant='bodyXS'>{data.format('DD/MM/YYYY')}</Typography>
        </Box>
        {img !== undefined && <ImagePeview src={img.src} width={img.width} />}

        <Typography variant='bodyMD'>{descricao}</Typography>
        {url !== undefined && (
          <Button
            variant='neutral'
            fullWidth
            onClick={() => {
              analyticsEventTracker({
                action: ANALYTICS_ACTIONS.NOVIDADES.REDIRECT,
              });
              window.open(url, '_blank');
            }}
          >
            {labelRedirect}
          </Button>
        )}
      </Stack>
    </Box>
  );
};
