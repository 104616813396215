import { listQueryParamsSchema, metaSchema } from 'api/axiosConfig';
import { ZodApiParamsParser, ZodApiResponseParser } from 'api/zodApi';
import { z } from 'zod';

export const schemaItemListarCbenefsResponse = z.object({
  cbenef: z.string(),
  descricao: z.string(),
});

const schemaListarCbenefsResponse = z.object({
  data: schemaItemListarCbenefsResponse.array(),
  meta: metaSchema,
});

const _schemaListarCbenefsParams = z
  .object({
    cst: z.number(),
    uf: z.string(),
    search: z.string().optional(),
    /**
     * Filtra o Cbenef pelos que temos mapeados que são utilizados por Produtores Rurais
     */
    produtor: z.number(),
  })
  .merge(listQueryParamsSchema);

export const parseListarCbenefsParams = ZodApiParamsParser.createParser(_schemaListarCbenefsParams);
export const parseListarCbenefsResponse = ZodApiResponseParser.createParser(schemaListarCbenefsResponse);

export type ListarCbenefsParams = z.infer<typeof _schemaListarCbenefsParams>;
export type ListarCbenefsResponseItem = z.infer<typeof schemaItemListarCbenefsResponse>;
