import { Outlet } from 'react-router';
import { useNotification } from 'hooks/useNotification/useNotification';
import { publicRouteMiddleware } from 'containers/hocs/publicRouteMiddleware/publicRouteMiddleware';
import Loading from 'components/loading/loading';

const Layout = () => {
  /**
   * Hook para exibir notificações
   */
  useNotification();

  return (
    <>
      <Loading />
      <Outlet />
    </>
  );
};

export default publicRouteMiddleware(Layout);
