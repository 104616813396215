import { captureException } from '@sentry/react';

import { has } from 'lodash-es';
import { ResponseType } from './parseError.static';
import getXMotivo from './getXMotivo';

type ResultParseError = {
  type: ResponseType;
  message: string;
  description?: string;
};

// Utilizado `any`, pois não é possível saber em qual formato o back-end retornará o error
function parseError(error: any): ResultParseError {
  let type = ResponseType.WARNING;
  let message = 'Ocorreu um problema';
  let description: string | undefined = undefined;

  const _error = error?.response?.data ?? error;
  if (has(_error, 'erro')) {
    type = ResponseType.ERROR;
    message = getXMotivo(_error) ?? _error.erro.mensagem;
  } else if (has(_error, 'errors') && Array.isArray(_error.errors)) {
    type = ResponseType.ERROR;
    message = _error.errors[0];
  } else if (has(_error, 'authErrors') || has(_error, 'autherrors')) {
    type = ResponseType.AUTH_ERROR;
    message = 'Você foi desconectado';
    description = 'Seu limite de tempo foi atingido ou alguma mudança ocorreu em sua conta. Faça login novamente.';
  } else if (has(_error, 'unauthorized')) {
    type = ResponseType.WARNING;
    message = _error.unauthorized.message;
    description = _error.unauthorized.description;
  } else if (has(_error, 'error')) {
    type = ResponseType.ERROR;
    message = _error.error.split(';')[0];
  } else if (
    _error?.message === 'Failed to fetch' ||
    _error?.message === 'Network Error' ||
    _error?.message === 'AxiosError: Network Error'
  ) {
    message = 'Verifique a sua conexão com a internet';
  } else {
    type = ResponseType.ERROR;
    message = _error?.message;
  }

  if (type === ResponseType.ERROR) {
    captureException(error);
  }

  return {
    type,
    message,
    description,
  };
}

export default parseError;
