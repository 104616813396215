import { useNavigate } from 'react-router';
import { DrawerWrapper } from './drawer.styles';
import { rotasDrawerAgro, rotasDrawerSimples } from './drawer.static';
import { Drawer as DrawerNova, LIcon } from '@s_mart/core';
import { Config, siteConfig } from 'config';

import useAuthStore from 'store/auth/auth';
import { EnumSistemas } from 'constants/sistemas';
import { EnumAutomacaoAgronota } from 'constants/configuracoes';
import useUsuarioSBSistemas from 'hooks/useUsuarioSBSistemas';

const Drawer = () => {
  const _navigate = useNavigate();

  const [recursosDisponiveis, rotaPrivadaInicial, perfil, migrouAutomacaoAgronota] = useAuthStore((s) => [
    s.recursosDisponiveis,
    s.rotaPrivadaInicial,
    s.perfil,
    s.configuracoes?.automacaoAgronota === EnumAutomacaoAgronota.CentralFinanceira,
  ]);

  const { isUsuarioSBSistemas } = useUsuarioSBSistemas();

  return (
    <DrawerWrapper>
      <DrawerNova>
        <DrawerNova.Header onClick={() => _navigate(rotaPrivadaInicial)}>
          <img src={siteConfig.logo} alt='Logo' />
        </DrawerNova.Header>
        <DrawerNova.Content color='#464646'>
          {Config.systemData.code === EnumSistemas.SIMPLESCTE &&
            rotasDrawerSimples(isUsuarioSBSistemas).map(
              (rota) =>
                !rota.hide && (
                  <DrawerNova.Item
                    key={rota.path}
                    icon={<LIcon icon={rota.icon} color='white' size='32px' />}
                    path={`/${rota.path}`}
                    onClick={() => _navigate(rota.path)}
                  >
                    {rota.label}
                  </DrawerNova.Item>
                ),
            )}
          {Config.systemData.code === EnumSistemas.AGRONOTA &&
            rotasDrawerAgro(recursosDisponiveis, migrouAutomacaoAgronota, perfil.emp).map((rota) =>
              !rota.hide ? (
                <DrawerNova.Item
                  key={rota.path}
                  icon={<LIcon icon={rota.icon} color='white' size='32px' />}
                  path={`/${rota.path}`}
                  onClick={() => _navigate(rota.path)}
                >
                  {rota.label}
                </DrawerNova.Item>
              ) : null,
            )}
        </DrawerNova.Content>
      </DrawerNova>
    </DrawerWrapper>
  );
};

export { Drawer };
