import {
  lineUserMagnifyGlass,
  lineHandHoldingUSD,
  lineUsers,
  lineWheat,
  lineLionHead,
  lineRobot,
  lineChartPie,
} from '@s_mart/light-icons';
import { EMPS_AGRONOTA_SEM_AUTOMACAO } from 'constants/empsSemAutomacoes';

import { has } from 'lodash-es';
import useAuthStore from 'store/auth/auth';
import { isStaging } from 'utils/isStaging';

const EMPS_SIMPLESCTE_RELATORIOS: Array<number | null> = [1272, 18215];

export const rotasDrawerSimples = (usuarioSBsistemas: boolean) => {
  const empresaAtual = useAuthStore.getState().empresaAtual;
  return [
    {
      key: 0,
      path: 'clientes',
      name: 'clientes',
      label: 'Clientes',
      icon: lineUsers,
    },
    {
      key: 1,
      path: 'relatorios',
      name: 'relatorios',
      label: 'Relatórios',
      icon: lineChartPie,
      hide: !EMPS_SIMPLESCTE_RELATORIOS.includes(empresaAtual) && !usuarioSBsistemas,
    },
  ];
};
// TODO remover todo if assim que esconder as telas para todo mundo
export const rotasDrawerAgro = (
  recursos: Record<string, boolean> | null,
  migrouAutomacaoAgronota: boolean,
  emp?: number,
) => {
  const esconderRotasSimuladorEProdutos = (emp !== undefined && emp > 21976) || isStaging();
  if (!recursos) {
    return [];
  }
  if (recursos && Object.values(recursos).length === 1 && has(recursos, 'simulador-ir-parceiro')) {
    return [
      {
        key: 3,
        path: 'simulador-ir',
        name: 'simulador-ir',
        label: 'Simulador IR',
        icon: lineLionHead,
        hide: recursos === null || !recursos?.['simulador-ir-parceiro'],
      },
    ];
  }
  return [
    {
      key: 0,
      path: 'resumo',
      name: 'resumo',
      label: 'Resumo',
      icon: lineUserMagnifyGlass,
    },
    {
      key: 1,
      path: 'grupos-produtores',
      name: 'grupos-produtores',
      label: 'Produtores e grupos',
      icon: lineUsers,
    },
    {
      key: 2,
      path: 'produtos',
      name: 'produtos',
      label: 'Produtos',
      icon: lineWheat,
      hide: esconderRotasSimuladorEProdutos,
    },
    {
      key: 3,
      path: 'plano-de-contas',
      name: 'plano-de-contas',
      label: 'Plano de contas',
      icon: lineHandHoldingUSD,
      hide:
        (!recursos?.['livro-caixa'] && !recursos?.['escrituracao-automatica']) ||
        EMPS_AGRONOTA_SEM_AUTOMACAO.includes(emp ?? -1) ||
        migrouAutomacaoAgronota,
    },
    {
      key: 4,
      path: 'simulador-ir',
      name: 'simulador-ir',
      label: 'Simulador IR',
      icon: lineLionHead,
      hide: recursos === null || !recursos?.['simulador-ir-parceiro'] || esconderRotasSimuladorEProdutos,
    },
    {
      key: 5,
      path: 'automacoes',
      name: 'automacoes',
      label: 'Automações',
      icon: lineRobot,
      hide:
        (!recursos?.['escrituracao-automatica'] && !recursos?.['nota-fiscal']) ||
        EMPS_AGRONOTA_SEM_AUTOMACAO.includes(emp ?? -1),
    },
  ];
};
